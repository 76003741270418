import React from 'react'
import { Suspense } from 'react';
import { Loading } from '../../../components/common';
import SEO from '../../../components/seo'
const SurveysForm = React.lazy(() => import('../../../Views/Surveys/SurveysForm'));

const IndexPage = () => {
    const isSSR = typeof window === "undefined"
    return (
        <>
            {!isSSR && (
                <Suspense fallback={<Loading />}>
                    <SEO title="New Surveys" />
                    <SurveysForm />
                </Suspense>
            )}
        </>
    )
}

export default IndexPage